import * as React from 'react';
import { PageProps } from 'gatsby';
import { ContentColumn, SEO } from 'src/components/common';

type Props = PageProps;

const NotFoundPage: React.FC<Props> = () => (
  <ContentColumn
    items={[
      {
        component: (
          <>
            <SEO title="404: Not found" />
            <h1>NOT FOUND</h1>
          </>
        ),
      },
    ]}
  />
);

export default NotFoundPage;
